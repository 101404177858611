<script lang="tsx">
import { defineComponentBaseUiSelect } from '@core/app/components/base/ui/BaseUiSelect.vue'
import { IconChevron } from '#components'
import type { BaseUiInputColors } from './BaseUiInput.vue'

type SelectColors = BaseUiInputColors
type SelectVariants = 'solid'
type SelectSizes = 'small' | 'medium'

export default defineComponentBaseUiSelect<SelectColors, SelectVariants, SelectSizes>({
    props: {
        color: {
            default: 'basic',
        },
        variant: {
            default: 'solid',
        },
        size: {
            default: 'medium',
        },
    },
    slots: {
        dropdownIcon: () => <IconChevron size="xs" />,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiSelect" as *;

@include select--disabled {
    opacity: 0.5;
}

@include select--error {
    background-color: $sim-c-white;
    outline: 1px solid $sim-c-danger-700;

    &:focus-within {
        outline-width: 2px;
        outline-offset: 0;
    }
}

@include placeholder {
    color: $sim-c-basic-800;

    @include sim-text-small;
    @include sim-font-regular;
}

@include side-content {
    color: $sim-c-black;
}

// --------------------------------------------------------------------
// Select sizes

@include select--size('small') {
    @include set-item-gap(0.625rem);
    @include set-padding(0.5rem, 1.5rem);
    border-radius: $sim-border-radius-xs;

    @include select--square('&') {
        @include set-padding(0.5rem, 0.5rem);
    }
}

@include select--size('medium') {
    @include set-item-gap(0.625rem);
    @include set-padding(0.85rem, 1.5rem);
    border-radius: $sim-border-radius-sm;
}

// --------------------------------------------------------------------
// Select colors

@include select--color('basic') {
    @include select--variant('solid', '&') {
        background-color: $sim-c-basic-200;
        color: $sim-c-primary-900;
    }
}

@include select--color('white') {
    @include select--variant('solid', '&') {
        background-color: $sim-c-white;
        color: $sim-c-primary-900;
    }
}

</style>
